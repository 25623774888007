<template>
  <div class="view-container">
    <div class="card qtype-table">
      <div class="card-header card-header-divider">
        <div class="card-title">Типы вопросов</div>
        <div class="tools">
          <router-link to="/test/question-type/edit" class="btn btn-accent"
            >Добавить</router-link
          >
        </div>
      </div>
      <div class="card-body">
        <question-type-table />
      </div>
    </div>
  </div>
</template>

<script>
import QuestionTypeTable from "../../feature/testing/tables/QuestionTypeTable.vue";
export default {
  components: { QuestionTypeTable },
  name: "questiontype-page",
};
</script>

<style>
</style>