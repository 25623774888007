<template>
  <div v-if="questionTypes">
    <ag-grid-vue
      :columnDefs="columnDefs.value"
      :rowData="rowData"
      :rowHeight="70"
      :headerHeight="90"
      :enableCellTextSelection="true"
      :ensureDomOrder="true"
      :pagination="true"
      :paginationPageSize="countOnPage"
      :defaultColDef="defaultColDef"
      :suppressRowHoverHighlight="true"
      :suppressPaginationPanel="true"
      :suppressRowClickSelection="true"
      :suppressRowTransform="true"
      style="width: 100%"
      domLayout="autoHeight"
      @grid-ready="onGridReady"
    ></ag-grid-vue>

    <v-pagination
      v-if="gridApi"
      v-model="page"
      @update:modelValue="changePage"
      :pages="gridApi.paginationGetTotalPages()"
      :range-size="1"
      active-color="transparent"
    />

    <div
      v-if="gridApi && gridApi.paginationGetTotalPages()"
      class="pagination-info"
    >
      <p>
        Показаны строки с {{ countOnPage * (page - 1) + 1 }} по
        {{ gridApi.paginationProxy.bottomDisplayedRowIndex + 1 }} из
        {{ gridApi.paginationGetRowCount() }}
      </p>
      <p>
        Страница {{ gridApi.paginationGetCurrentPage() + 1 }} из
        {{ gridApi.paginationGetTotalPages() }}
      </p>
    </div>
    <div class="pagination-info" v-else>Нет результатов</div>
  </div>
  <div v-else class="table-preloader">
    <preloader />
  </div>
</template>

<script>
import VPagination from "@hennge/vue3-pagination";
import "ag-grid-community/dist/styles/ag-grid.css";
import { AgGridVue } from "ag-grid-vue3";
import { reactive, ref } from "@vue/reactivity";
import EditRenderer from "../../../components/Tables/CellRenderers/EditRenderer.vue";
import ColorRenderer from "../../../components/Tables/CellRenderers/ColorRenderer.vue";
import { useStore } from "vuex";
import { computed, onBeforeMount, watch } from "@vue/runtime-core";
import Preloader from "../../../components/Technical/Preloader.vue";
export default {
  name: "qtype-table",
  components: {
    VPagination,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    EditRenderer,
    // eslint-disable-next-line vue/no-unused-components
    ColorRenderer,
    Preloader,
  },
  setup() {
    const store = useStore(),
      gridApi = ref(null),
      columnApi = ref(null);

    const onGridReady = (params) => {
      gridApi.value = params.api;
      columnApi.value = params.columnApi;
    };
    const countOnPage = ref(15),
      page = ref(1),
      isPreloader = ref(false);
    const questionTypes = computed(() => store.state.test.questionTypes),
      rowData = computed(() => {
        return questionTypes.value.map((type) => {
          return {
            id: type.id,
            name: type.name,
            color: type.color,
            edit: type.id,
          };
        });
      });

    const columnDefs = reactive({
      value: [
        {
          field: "id",
          hide: true,
        },
        {
          field: "name",
          headerName: "Название",
          wrapText: true,
          minWidth: 255,
        },
        {
          field: "color",
          headerName: "Цвет",
          width: 170,
          flex: 0,
          cellRenderer: "ColorRenderer",
        },
        {
          field: "edit",
          width: 70,
          headerName: "",
          flex: 0,
          cellRenderer: "EditRenderer",
          cellRendererParams: {
            source: "test/question-type",
            end: "edit",
          },
        },
      ],
    });
    const defaultColDef = {
      suppressMovable: true,
      flex: 1,
      autoHeight: true,
    };

    const changePage = (page) => {
      gridApi.value.paginationGoToPage(page - 1);
    };

    watch(countOnPage, () => {
      gridApi.value.paginationSetPageSize(countOnPage.value);
      page.value = 1;
    });

    onBeforeMount(() => {
      store.dispatch("test/getQuestionTypes");
    });

    return {
      countOnPage,
      isPreloader,
      page,
      changePage,
      questionTypes,

      columnDefs,
      rowData,
      defaultColDef,
      gridApi,
      onGridReady,
      columnApi,
    };
  },
};
</script>

<style lang="scss" scoped>
.qtype-table {
  &__color {
    height: 13px;
    width: 13px;
    display: inline-block;
    margin-right: 4px;
    border: 1px solid var(--cream-color);
  }
}
</style>
